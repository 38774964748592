<template>
  <el-card id="creatSeat">
    <header>
      <h2>挖掘线索历史记录</h2>
      <div class="search">
        <div class="left">
          <!-- <div
            class="left-wrap"
            v-if="roleName == '企业' || roleName == '组长'"
          >
            <span>员工列表</span>
            <el-row class="demo-autocomplete">
              <el-col :span="24">
                <el-autocomplete
                  class="inline-input"
                  v-model="historyObj.userId"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入员工"
                  @select="handleSelect"
                  size="mini"
                ></el-autocomplete>
              </el-col>
            </el-row>
          </div> -->
          <div class="left-wrap" v-if="roleName == '个人' || roleName == '组长'">
            <span>创建人</span>
            <el-input v-model="historyObj.createUserName" placeholder="请输入创建人" clearable size="mini"></el-input>
          </div>
          <div class="left-wrap" v-if="roleName == '个人' || roleName == '组长'">
            <span>电话号码</span>
            <el-input v-model="historyObj.phone" placeholder="请输入电话号码" clearable size="mini"></el-input>
          </div>
          <div class="left-wrap" v-if="roleName == '个人' || roleName == '组长'">
            <span>公司名称</span>
            <el-input v-model="historyObj.companyName" placeholder="请输入公司名称" clearable size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>企业名称</span>
            <el-input v-model="historyObj.entname" placeholder="请输入企业名称" clearable size="mini"></el-input>
          </div>
          <div class="left-wrap" v-if="roleName == '企业'">
            <span>创建日期</span>
            <el-date-picker v-model="historyObj.createTime" size="mini" type="datetimerange" range-separator="——"
              start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch()" class="searchBtn"
            size="mini">查找</el-button>

          <el-button type="primary" icon="el-icon-refresh" @click="reset()" class="reset" size="mini">重置</el-button>
        </div>
      </div>
    </header>
    <!-- <hr> -->
    <hr>

    <main>
      <el-table :data="tableData" border class="table" ref="multipleTable" @row-dblclick="openDrawer"
        @selection-change="handleSelectionChange" header-cell-class-name="table-header">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="entname" label="公司名称" align="center" width="300"></el-table-column>
        <el-table-column v-for="v in TableTitleArr" :prop="v.prop" :label="v.label" align="center"></el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex" :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </main>
    <!--  创建搜客宝账号弹出框 -->
    <el-dialog title="升级成其他套餐" :visible.sync="seatVisible" width="30%" @close="closeSkb">
      <el-form label-width="100px">
        <el-form-item label="选择套餐">
          <el-select v-model="packageValue" filterable placeholder="请选择套餐">
            <el-option v-for="item in options" :key="item.type" :label="'日查' + item.view_contact_num" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div style="color: rgba(252, 117, 13, 1)">
            注意套餐不可以降级处理!
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="seatVisible = false">取 消</el-button>
          <el-button type="primary" @click="isUpgrade">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 所有电话号码信息抽屉 -->
    <el-drawer :title="currentCName + ' 联系电话列表'" :visible.sync="tabledrawer" direction="rtl" size="40%">
      <div style="
          width: 94%;
          margin: auto;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
        ">
        <el-table :data="gridData">
          <el-table-column fixed label="号码状态" prop="telephoneStatus" width="auto" align="center">
            <template v-slot="scope">
              <div :class="{
                redFont: scope.row.telephoneStatus != '1',
                greenFont: scope.row.telephoneStatus == '1',
              }">
                {{ setName(scope.row.telephoneStatus) }}
              </div>
            </template></el-table-column>
          <el-table-column v-for="(v, i) in dataLabel" :key="i" :label="v.label" :prop="v.name" width="120"
            align="center"></el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { phoneFindUser } from "@/api/phone";
import { userFindlist } from "@/api/user";
import {
  skbHistoryData,
  selectAccount,
  selectAccountUser,
  queryMeal,
  upgradeMeala,
  updateAccount,
  selectClueId,
} from "@/api/skb";
export default {
  data() {
    return {
      pickerOneDate: '',
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = '';
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围
          //未选择第一个时间的情况下可选范围只能选择过去的时间
          if (this.pickerOneDate) {
            const day = 30 * 24 * 3600 * 1000 // 15天
            let maxTime = this.pickerOneDate + day
            let minTime = this.pickerOneDate - day
            return time.getTime() > maxTime || time.getTime() < minTime
          } else {
            // return time.getTime() > Date.now()
          }
        }
      },
      gridData: [
        {
          telephoneStatus: 0,
          telephone: "电话号码",
          qq: "qq",
          address: "地址",
          contact: "联系人姓名",
          email: "邮箱",
          sourceName: "来源站",
        },
      ],
      dataLabel: [
        {
          label: "电话号码",
          name: "telephone",
        },
        {
          label: "qq",
          name: "qq",
        },
        {
          label: "地址",
          name: "address",
        },
        {
          label: "联系人姓名",
          name: "contact",
        },
        {
          label: "邮箱",
          name: "email",
        },
        {
          label: "来源站",
          name: "sourceName",
        },
      ],
      tabledrawer: false,
      historyObj: {
        createUserName: null,
        phone: null,
        companyName: null,
        createTime: null,
        entname: null,
      },
      userOptions: [],
      userValue: null,
      seatVisibleUser: false,
      packageValue: null,
      options: [],
      seatVisible: false,
      uid: null,
      restaurants: [],
      TableTitleArr: [
        {
          prop: "createUserName",
          label: "创建用户姓名",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "type",
          label: "套餐类型",
        },
        {
          prop: "telephoneMobile",
          label: "企业移动电话",
        },
        {
          prop: "telephoneFixed",
          label: "企业固定电话",
        },
        {
          prop: "qq",
          label: "企业QQ号码",
        },
        {
          prop: "email",
          label: "企业邮箱",
        },
        {
          prop: "address",
          label: "企业联系地址",
        },
      ],
      numsArray: [], //选中号码的ID
      orderBySeatMoney: null,

      fileList: [],
      encryption: false,
      realname: null,
      roleName: null,
      parentld: null,
      id: null,
      name: null,
      value: null,
      userId: null,
      form: {},
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      currentKh: {},
      currentCName: "",
    };
  },
  async created() {
    this.isMonthEnd();
    this.parentld = localStorage.getItem("parentld");
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.encryption = localStorage.getItem("encryption");



    // this.beginTime = this.formatDate(new Date()).substring(0, 10) + " 00:00:00"
    // this.endTime = this.formatDate(new Date()).substring(0, 10) + " 23:59:59"
    this.historyObj.createTime = [this.formatDate(new Date()).substring(0, 10) + " 00:00:00", this.formatDate(new Date()).substring(0, 10) + " 23:59:59"]


    await this.getCompanyUid();
    // await this.getStaffInfo();
    await this.getList(this.historyObj);

    await this.queryMealInfo();
    await this.getIsUsername();
    if(this.roleName != '人员'){
      await this.getUserList();
    }
  },
  methods: {
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    // 设置号码状态
    setName(name) {
      // 0 →未验证, 1 → 实号，2 → 沉默号，空号，停机，库无 3→非法号码
      if (name == 0) {
        return "未验证号码";
      } else if (name == 1) {
        return "可用实号";
      } else if (name == 2) {
        return "虚假空号";
      } else if (name == 3) {
        return "非法号码";
      }
    },
    // 选择员工
    handleSelect(item) {
      this.currentKh = item;
      console.log(item);
    },
    // 输入建议
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // 打开所有员工号码弹框
    async openDrawer(scope) {
      console.log("scope", scope);
      this.currentCName = scope.entname;
      let res = await selectClueId({ clueId: scope.clueId });
      this.gridData = res.data.data;
      this.tabledrawer = true;
    },

    // 获取所员工信息
    async getUserList() {
      let res = await phoneFindUser();
      if (res.data.statusCode == "00000") {
        this.userOptions = res.data.data.user;
        this.userOptions.push({
          id: localStorage.getItem("id"),
          realname: localStorage.getItem("realname"),
        });
      }
      // console.log("获取所员工信息", res.data);
    },
    // 获取有员工
    async getIsUsername() {
      let params = { page: 1, size: this.pageTotal };
      let res = await selectAccount(params);
      console.log("res", res.data);
      if (res.data.data) {
        this.restaurants = res.data.data.map((v) => {
          if (v.username) {
            v.value = v.createUserName;
            return v;
          }
        });
      }
    },
    // 关闭搜客宝弹框
    closeSkb() {
      this.packageValue = null;
    },
    // 关闭搜客宝弹框
    closeSkbUser() {
      this.userValue = null;
    },

    // 确认升级
    async isUpgrade() {
      let params = {
        uid: this.form.uid,
        type: this.packageValue,
      };
      let res = await upgradeMeala(params);
      if (res.data.statusCode == "00000") {
        console.log("查询套餐信息", res.data);
        Message.success("升级成功");
        this.getList();
      } else {
        Message.error(res.data.message);
      }
      this.seatVisible = false;
    },
    // 打开升级弹框
    upgrade(row) {
      console.log("row", row);
      this.form = row;
      this.seatVisible = true;
    },
    upDateUser(row) {
      console.log("row", row);
      this.form = row;
      this.seatVisibleUser = true;
    },

    // 查询套餐信息
    async queryMealInfo() {
      let params = {};
      let res = await queryMeal(params);
      if (res.data.statusCode == "00000") {
        console.log("查询套餐信息", res.data);
        this.options = res.data.data.meals;
      }
    },
    // 获取公司信息
    async getCompanyUid() {
      let res1 = await selectAccount({
        page: "1",
        size: "10",
      });
      let data = res1.data.data;
      // console.log("res1,re", res1);
      if (data.length != 0) {
        this.uid = data[0].uid;
      }
    },
    // 获取已开通员工信息
    async getStaffInfo() {
      // if (this.uid) {
      //   let res2 = await selectAccountUser({
      //     uid: this.uid,
      //   });
      //   if (res2.data.statusCode == "00000") {
      //     // this.tableData = res2.data.data;
      //     console.log("开通的员工", res2.data.data);
      //     let data = res2.data.data;
      //     this.restaurants = data.map((v) => {
      //       v.value = v.name;
      //       return v;
      //     });
      //   }
      // }
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    // 搜索
    handleSearch() {
      this.page = 1;
      this.getList(this.historyObj);
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.id);
      });
      // console.log(111111111, this.numsArray);
    },

    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 月底隐藏删除按钮
    isMonthEnd() {
      let date = new Date();
      let currentMonth = date.getMonth();
      let nextMonth = ++currentMonth;
      let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
      let oneDay = 1000 * 60 * 60 * 24;
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let day = lastTime.getDate();
      console.log("当月最后一天", day);
      let currentDay = date.getDate(); // 获取当前日(1-31)
      console.log("当天", currentDay);
      if (currentDay == day) {
        return (this.lastDay = 1);
      } else {
        return (this.lastDay = 0);
      }
    },
    // 展示
    getList(obj) {
      if (this.timer) {
        Message.warning("请勿频繁操作~");
      }
      clearTimeout(this.timer);

      this.timer = setTimeout(async () => {
        let params = { page: this.page, size: this.size };
        if (obj) {
          params.entname = obj.entname;
          params.createUserName = obj.createUserName;
          params.phone = obj.phone;
          params.companyName = obj.companyName;
          if (obj.createTime) {
            (params.beginTime = this.getYMDHMS(
              "YYYY-mm-dd HH:MM:SS",
              obj.createTime[0]
            )),
              (params.endTime = this.getYMDHMS(
                "YYYY-mm-dd HH:MM:SS",
                obj.createTime[1]
              ));
          }
        }
        let res = await skbHistoryData(params);
        this.pageTotal = res.data.total;
        this.tableData = res.data.data;
        this.timer = null
      }, 500);
    },
    //重置
    reset() {
      this.page = 1;
      this.historyObj = {
        createUserName: null,
        phone: null,
        companyName: null,
        createTime: null,
        entname: null,
      };
      this.getList();
    },

    //下载模板
    upload() {
      window.open("http://123.60.30.116:9005/seat-phone.xls");
    },
    handleRemove(file, fileList) {
      console.log(11111111111, file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #FFF;
  background-color: #3278FE;
  border-color: #3278FE;

  &:hover {
    opacity: 0.7;
  }
}

#creatSeat {
  height: 100%;
  overflow-y: scroll;

  header {
    h1 {
      font-weight: normal;
    }

    .search {
      display: flex;
      margin-top: 10px;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        flex: 1;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            width: 28%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }

  .uploadBtn {
    width: 68%;
  }

  /deep/ .el-table__row {
    user-select: none;
  }
}

.redFont {
  font-weight: bold;
  color: red;
}

.greenFont {
  font-weight: bold;
  color: green;
}
</style>